export default ($) => ({
	openpopup(id){
        $('.popup'+id).fadeIn(300);
        $('body').addClass('lock');
    },
    closepopup(id){
        $('.popup'+id).fadeOut(300);
      	$('body').removeClass('lock');  
    },
    btnpopup(){
        const _th = this
        $('.close-popup,.close,.overlay').on('click', function(e){
        	e.preventDefault();
            const idpopup = '#'+$(this).parents('.popup').attr('id')
            _th.closepopup(idpopup)
        })
    },
    popupdetail(){
    	const _th = this
    	$('.pop-here').on('click',function(e){
    		e.preventDefault();
    		const idpopup = '#pop-detail'
            _th.openpopup(idpopup)
    	})
    },
    init(){
        this.btnpopup()
        this.popupdetail()
    }
    
})
