import * as $ from 'jquery';
import helper  from './libs/helper.js'
import headerFunction from './libs/header'//import the js file
import popup  from './libs/popup.js'
import 'lazyload';

window.$ = $


$(document).ready(() => {
    
	let images = $(".lazyload");
	lazyload(images);

    helper($).init()
    headerFunction($).init()
    popup($).init()
    $('.banner-home .slider .list figure img,.static-banner figure img').responsiveImage({
      type:"image"
    });

    $('.tab li a').click(function(){
      const id = $(this).attr('data-id');
      $('.tab li a').not($(this)).removeClass('active');
      $(this).addClass('active');
      $('.tab-content > div').not('#'+id).removeClass('active');
      $('.tab-content > div#'+id).addClass('active');
    });

    $('.language label').on('click', function(){
        if ( $(this).hasClass('active') ) {
            $(this).removeClass('active').find('.language__box').fadeOut();
        }else{
            $(this).addClass('active').find('.language__box').fadeIn();
        }
    });

    // hasTouch();
    if(window.innerWidth < 1201) {
        $('header .wrapper .right-header nav > ul > li.have-sub a').on('click', function(){
            if ( $(this).parent().hasClass('active') ) {
                $(this).parent().removeClass('active').find('> ul').slideUp();
            }else{
                $(this).parent().addClass('active').find('> ul').slideDown();
            }
        });
    }
})

$(document).mouseup(function (e) { 
    if ($(e.target).closest(".language label").length === 0) { 
        $('.language label').removeClass('active').find('.language__box').fadeOut();
    } 
})

$.fn.responsiveImage = function (s) {
    let e ={
        type:undefined
    }
    e = $.extend(e,s);
    let elem = this, etype,
            action = function () {
                if(e.type == undefined){
                    etype = "background";
                }else{
                    etype = e.type;
                }
                let window_width;
                window_width = $(window).width();
                elem.each(function () {
                    let flag = false;
                    let images_url;
                    if (window_width > 1023 && $(this).attr('has_load') != 'large') {
                        images_url = $(this).attr('data-img-large');
                        $(this).attr('has_load', 'large');
                        flag = true;
                    } else if (window_width <= 1023 && window_width >= 680 && $(this).attr('has_load') != 'medium') {
                        images_url = $(this).attr('data-img-medium');
                        $(this).attr('has_load', 'medium');
                        flag = true;
                    } else if (window_width < 680 && window_width >= 0 && $(this).attr('has_load') != 'small') {
                        images_url = $(this).attr('data-img-small');
                        $(this).attr('has_load', 'small');
                        flag = true;
                    }
                    if (images_url == undefined) {
                        images_url = $(this).attr('data-img-large');
                        $(this).attr('has_load', 'large');
                    }

                    if (flag){
                        if(etype == "background"){
                            $(this).css('background-image', 'url('+images_url+')');
                        }else{
                            $(this).attr('src', images_url);
                        }     
                    }     
                });

            }
    action();
   
    var resizeTimer;

    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            action();
        }, 500);
    });
}

// function hasTouch() {
//     return 'ontouchstart' in document.documentElement
//            || navigator.maxTouchPoints > 0
//            || navigator.msMaxTouchPoints > 0;
// }

// if (hasTouch()) { // remove all :hover stylesheets
//     try { // prevent exception on browsers not supporting DOM styleSheets properly
//         for (var si in document.styleSheets) {
//             var styleSheet = document.styleSheets[si];
//             if (!styleSheet.rules) continue;

//             for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
//                 if (!styleSheet.rules[ri].selectorText) continue;

//                 if (styleSheet.rules[ri].selectorText.match(':hover')) {
//                     styleSheet.deleteRule(ri);
//                 }
//             }
//         }
//     } catch (ex) {}
// }

window.openPopup = (id) => {
    popup($).openpopup(id)
}

$(document).mouseup(function (e) { 
    if (window.innerWidth <= 1100) {
        if ($(e.target).closest(".right-header").length === 0) { 
            var rightHeader = $('.right-header');
            rightHeader.fadeOut(300)
            $('header .right-header nav ul li.have-child ul').hide()
            $('header .right-header nav ul li').removeClass("act")
        } 
    }
}); 