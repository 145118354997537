import $ from 'jquery';
import 'select2';  
import 'select2/src/scss/core.scss';

const fucn = {};

export default ($) => ({
	select2() {
		$('.select2').select2({
        	width: '100%'
        }); 
	},
  burgerMenu(){
    const w = $(window).width()
    $('header .right-header nav ul li').each(function(){
      if($(this).children('ul').length){
        $(this).addClass('have-child')
      } else if($(this).children('ul li ul').length) {
        $(this).addClass('have-child-sub')
      }
    })
    if(w <= 1100){
      const rightHeader = $('header .right-header')
      $('.burger-nav').on('click',function(){
        rightHeader.fadeIn(300)
      })
      $(document).on('click','.close-nav',function(){
        rightHeader.fadeOut(300)
        $('header .right-header nav ul li.have-child ul').hide()
        $('header .right-header nav ul li').removeClass("act")
      })
      $('header .right-header nav ul li.have-child', 'header .right-header nav ul li.have-child li.have-child').on('click', function(e){
        e.preventDefault();
        if(!$(this).hasClass('act')){
          $(this).addClass('act')
          $(this).parent().children('ul').slidedown(300);
        } else {
          $(this).removeClass('act')
          $(this).parent().children('ul').slideup(300)
        }
      });
    }
  },
  floatingMenu(){
    var header = $("header"),
          fnc = {},
          ww = $(window).width();
          
    fnc.runfnc = function () {
        var topmin = 0;
        if($(window).scrollTop() > topmin){
            if(!header.hasClass("float")){
                header.addClass("float");
            }
        }else{
            if(header.hasClass("float")){
                header.removeClass("float");
            }
        }
    } 
    $(window).scroll(function () {
        fnc.runfnc();
    })
    fnc.runfnc();
  },
  init(){
    this.select2()
    this.burgerMenu()
    this.floatingMenu()
  }
})