export default ($) => ({
  
  menuHeader(){
    const this_ = this
    $('header .right-header nav ul li').each(function(){
      if($(this).children('ul').length){
        $(this).addClass('have-sub')
      }
    })
    $('header .right-header nav ul li ul .have-sub .parent .arrow').on('click',function(e){
      e.preventDefault();
      if(!$(this).hasClass('act')){
        $(this).addClass('act')
        $(this).parents('.have-sub').addClass('active');
        $('.have-sub > .parent > .arrow').not($(this)).parents('> .have-sub').removeClass('active').find('.arrow').removeClass('act');
        $(this).parents('.sub-img').find('> figure').attr('style', 'margin-left: ' + $(this).parents('.sub-img').find('> ul').innerWidth() + 'px');
        if(window.innerWidth <= 1100) {
          $('.have-sub > .parent > .arrow').not($(this)).parents('> .have-sub').removeClass('active').find('.arrow').removeClass('act').parent().parent().find('> ul').slideUp();
          $(this).parents('.have-sub').find('> ul').slideDown();
        }
        // this_.adjustHeightHeader($(this).parents('.submenu'))
      } else {
        $(this).removeClass('act')
        $(this).parent().parent().removeClass('active');
        $(this).parents('.sub-img').find('> figure').removeAttr('style');
        if(window.innerWidth <= 1100) {
          $(this).parent().parent().find('> ul').slideUp();
        }
      }
    })
    if (window.innerWidth <= 1100) {
      $('header .wrapper .right-header nav ul li.dropdown > .parent > .arrow').on('click',function(e){
        e.preventDefault();
        if(!$(this).hasClass('act')){
          $(this).addClass('act')
          $(this).parents('.dropdown').addClass('active').find('> .submenu').slideDown();
          $('header .wrapper .right-header nav ul li.dropdown > .parent > .arrow').not($(this)).parents('.dropdown').removeClass('active').find('.submenu').slideUp();
        } else {
          $(this).removeClass('act')
          $(this).parents('.dropdown').removeClass('active').find('> .submenu').slideUp();
        }
      })
    }
  },
  adjustHeightHeader(elem){
    const heights = []
    elem.find('ul').each(function(){
      heights.push(parseFloat($(this).outerHeight().toFixed(0))) 
    })
    const result = heights.sort((a, b) => b-a);
    elem.find('ul').outerHeight(result[0])
    // console.log(result)
  },
  userDrop(){
    $('.user-header .ico').on('click',function(){
      if(!$(this).parent().hasClass('act')){
        $(this).parent().addClass('act')
        $(this).parent().find('.drop-user').fadeIn(300)
        $(this).parents('header').find('.overlay').fadeIn(300)
        $('.search-header .drop-search').hide();
        $('.lang-header .drop-lang').hide();
        $('.search-header').removeClass('act')
        $('.lang-header').removeClass('act')
      } else {
        $(this).parent().removeClass('act')
        $(this).parent().find('.drop-user').fadeOut(300)
        $(this).parents('header').find('.overlay').fadeOut(300)

      }
    })
    $('header .overlay').on('click',function(){
      $(this).parent().find('.tools').removeClass('act')
      $(this).parent().find('.drop-user').fadeOut(300)
      $(this).fadeOut(300)
    })
  },
  languageDrop(){
    $('.lang-header .ico').on('click',function(){
      if(!$(this).parent().hasClass('act')){
        $(this).parent().addClass('act')
        $(this).parent().find('.drop-lang').fadeIn(300)
        $(this).parents('header').find('.overlay').fadeIn(300)
        $('.search-header .drop-search').hide()
        $('.user-header .drop-user').hide()
        $('.search-header').removeClass('act')
        $('.user-header').removeClass('act')
      } else {
        $(this).parent().removeClass('act')
        $(this).parent().find('.drop-lang').fadeOut(300)
        $(this).parents('header').find('.overlay').fadeOut(300)

      }
    })
    $('header .overlay').on('click',function(){
      $(this).parent().find('.tools').removeClass('act')
      $(this).parent().find('.drop-lang').fadeOut(300)
      $(this).fadeOut(300)
    })
  },
  searchDrop(){
    $('.search-header .ico').on('click',function(){
      if(!$(this).parent().hasClass('act')){
        $(this).parent().addClass('act')
        $(this).parent().find('.drop-search').fadeIn(300)
        $(this).parents('header').find('.overlay').fadeIn(300)
        $('.lang-header .drop-lang').hide()
        $('.user-header .drop-user').hide()
        $('.lang-header').removeClass('act')
        $('.user-header').removeClass('act')
      } else {
        $(this).parent().removeClass('act')
        $(this).parent().find('.drop-search').fadeOut(300)
        $(this).parents('header').find('.overlay').fadeOut(300)

      }
    })
    $('header .overlay').on('click',function(){
      $(this).parent().find('.tools').removeClass('act')
      $(this).parent().find('.drop-search').fadeOut(300)
      $(this).fadeOut(300)
    })
  },
  varHeight(){
    const setHeight = function(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setHeight();
    $(window).on('load',function () {
      setHeight();          
    });
  },

  init() { 
    this.menuHeader();
    this.varHeight();	 
    this.userDrop(); 
    this.languageDrop(); 
    this.searchDrop(); 
  }
});
